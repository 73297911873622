<template>
    <b-list-group>
        <div v-if="projects.length > 0">
            <div
                v-for="item in projects"
                :key="item.id_projetos"
                class="border-0 border-bottom rounded-0 py-1 d-flex justify-content-between align-items-center"
            >
                <div class="d-flex align-items-center">
                    <b-button
                        class="btn-ver"
                        pill
                        size="sm"
                        @click="
                            $router.push({
                                name: 'Info',
                                params: {
                                    projectId: item.id_projetos,
                                },
                            })
                        "
                    >
                        <i class="fa fa-eye" aria-hidden="true"></i>
                        Ver
                    </b-button>
                    <div class="text">
                        <span>{{ item.pro_empresa }}</span>
                        |
                        <span style="font-weight: bold;">{{
                            item.pro_nome
                        }}</span>
                    </div>
                </div>

                <div class="d-flex align-items-center">
                    <b-button
                        class="btn-votar"
                        @click="openVote(item.id_projetos)"
                        size="sm"
                        pill
                        >Votar
                    </b-button>
                    <!-- <b-icon
                        icon="circle"
                        scale="1"
                        variant="danger"
                        v-if="true"
                    ></b-icon> -->
                    <!-- <b-icon
                        icon="check-circle-fill"
                        scale="1"
                        variant="success"
                        v-else
                    ></b-icon> -->
                </div>
            </div>
        </div>
        <div v-else>
            Sem registros para exibir.
        </div>
    </b-list-group>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'listProjects',
    props: ['projects'],
    computed: {
        ...mapState(['listProjects']),
    },
    methods: {
        ...mapMutations(['SET_PROJECT_ID', 'SET_PROJECT']),
        openVote(id) {
            this.SET_PROJECT_ID(id);
            this.SET_PROJECT(
                this.listProjects.find((x) => x.id_projetos === id)
            );

            this.$root.$emit('bv::toggle::modal', 'modal', '#btnToggle');
        },
    },
};
</script>

<style></style>
