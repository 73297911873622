import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state: {
        login: false,
        user: {},
        keyLogin: null,
        listProjects: [],
        listEspecial: [],
        listGeral: [],
        idProject: null,
        project: null,
    },
    mutations: {
        UPDATE_USER(state, payload) {
            state.user = payload;
        },
        UPDATE_LOGIN(state, payload) {
            state.login = payload;
        },
        SET_KEY_LOGIN(state, payload) {
            state.keyLogin = payload;
        },
        SET_LIST_PROJECT(state, payload) {
            state.listProjects = payload;
        },
        SET_LIST_ESPECIAL(state, payload) {
            state.listEspecial = payload;
        },
        SET_LIST_GERAL(state, payload) {
            state.listGeral = payload;
        },
        SET_PROJECT_ID(state, payload) {
            state.idProject = payload;
        },
        SET_PROJECT(state, payload) {
            state.project = payload;
        },
    },
    actions: {
        logOutUser(context) {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('project');
            context.commit('UPDATE_USER', {});
            context.commit('SET_KEY_LOGIN', '');
            context.commit('UPDATE_LOGIN', false);
        },
    },
    modules: {},
});
