<template>
    <div>
        <b-modal
            id="modal"
            ref="modal"
            size="xl"
            hide-header-close
            hide-header
            hide-footer
            cancel-title
            no-close-on-esc
            no-close-on-backdrop
        >
            <div class="close d-flex justify-content-end">
                <b-icon
                    icon="x-circle"
                    @click="closeModal"
                    class="closeModal"
                ></b-icon>
            </div>

            <!-- criterio1 -->
            <div class="m-3">
                <div class="d-xl-flex d-block align-items-center">
                    <div class="txt">
                        <p class="titulo m-0">
                            Valores
                            <span v-b-toggle.collapse-1>
                                <b-icon
                                    icon="exclamation-circle"
                                    class="icon"
                                ></b-icon>
                            </span>
                        </p>
                    </div>

                    <div class="d-block d-xl-none smallTxt ">
                        <b-collapse id="collapse-1" class="mt-2 txtInfo">
                            <span class="small">
                                Alinhamento do projeto aos valores da Edenred: Imaginação, Respeito, Paixão pelos Clientes, Espírito Empreendedor e Simplicidade.
                            </span>
                        </b-collapse>
                    </div>

                    <div class="d-lg-flex justify-content-between myBtn">
                        <b-button
                            :class="{ selected: this.votos.criterio1 == '1' }"
                            @click="votos.criterio1 = 1"
                            squared
                            variant="outline-secondary"
                            >Não se aplica</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio1 == '2' }"
                            @click="votos.criterio1 = 2"
                            squared
                            variant="outline-secondary"
                            >Não atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio1 == '3' }"
                            @click="votos.criterio1 = 3"
                            squared
                            variant="outline-secondary"
                            >Atende parcial</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio1 == '4' }"
                            @click="votos.criterio1 = 4"
                            squared
                            variant="outline-secondary"
                            >Atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio1 == '5' }"
                            @click="votos.criterio1 = 5"
                            squared
                            variant="outline-secondary"
                            >Supera</b-button
                        >
                    </div>
                </div>
                <div class="d-none d-xl-block smallTxt ">
                    <b-collapse id="collapse-1" class="mt-2 txtInfo">
                        <span class="small">
                            Alinhamento do projeto aos valores da Edenred: Imaginação, Respeito, Paixão pelos Clientes, Espírito Empreendedor e Simplicidade.
                        </span>
                    </b-collapse>
                </div>
            </div>

            <!--  Eficiência/Produtividade -->
            <div class="m-3">
                <div class="d-xl-flex d-block align-items-center">
                    <div class="txt">
                        <p class="titulo m-0">
                          Inovação
                          <span v-b-toggle.collapse-2>
                                <b-icon
                                    icon="exclamation-circle"
                                    class="icon"
                                ></b-icon>
                            </span>
                        </p>
                    </div>

                    <div class="d-block d-xl-none smallTxt ">
                        <b-collapse id="collapse-2" class="mt-2 txtInfo">
                            <span class="small">
                                Desenvolvimento de novas abordagens, soluções disruptivas e/ou alternativas em relação aos produtos, serviços e/ou práticas que tiveram/terão um benefício significativo para a área.
                            </span>
                        </b-collapse>
                    </div>

                    <div class="d-lg-flex justify-content-between myBtn">
                        <b-button
                            :class="{ selected: this.votos.criterio2 == '1' }"
                            @click="votos.criterio2 = 1"
                            squared
                            variant="outline-secondary"
                            >Não se aplica</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio2 == '2' }"
                            @click="votos.criterio2 = 2"
                            squared
                            variant="outline-secondary"
                            >Não atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio2 == '3' }"
                            @click="votos.criterio2 = 3"
                            squared
                            variant="outline-secondary"
                            >Atende parcial</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio2 == '4' }"
                            @click="votos.criterio2 = 4"
                            squared
                            variant="outline-secondary"
                            >Atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio2 == '5' }"
                            @click="votos.criterio2 = 5"
                            squared
                            variant="outline-secondary"
                            >Supera</b-button
                        >
                    </div>
                </div>
                <div class="d-none d-xl-block smallTxt ">
                    <b-collapse id="collapse-2" class="mt-2 txtInfo">
                        <span class="small">
                            Desenvolvimento de novas abordagens, soluções disruptivas e/ou alternativas em relação aos produtos, serviços e/ou práticas que tiveram/terão um benefício significativo para a área.
                        </span>
                    </b-collapse>
                </div>
            </div>

            <!--  Excelência em Serviços -->
            <div class="m-3">
                <div class="d-xl-flex d-block align-items-center">
                    <div class="txt">
                        <p class="titulo m-0">
                          Foco no cliente
                          <span v-b-toggle.collapse-3>
                                <b-icon
                                    icon="exclamation-circle"
                                    class="icon"
                                ></b-icon>
                            </span>
                        </p>
                    </div>

                    <div class="d-block d-xl-none smallTxt ">
                        <b-collapse id="collapse-3" class="mt-2 txtInfo">
                            <span class="small">
                                O projeto atende as necessidades dos clientes (interno/externo) de uma forma direta ou indireta, que os surpreenda positivamente, oferecendo uma solução que supera suas expectativas.
                            </span>
                        </b-collapse>
                    </div>

                    <div class="d-lg-flex justify-content-between myBtn">
                        <b-button
                            :class="{ selected: this.votos.criterio3 == '1' }"
                            @click="votos.criterio3 = 1"
                            squared
                            variant="outline-secondary"
                            >Não se aplica</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio3 == '2' }"
                            @click="votos.criterio3 = 2"
                            squared
                            variant="outline-secondary"
                            >Não atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio3 == '3' }"
                            @click="votos.criterio3 = 3"
                            squared
                            variant="outline-secondary"
                            >Atende parcial</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio3 == '4' }"
                            @click="votos.criterio3 = 4"
                            squared
                            variant="outline-secondary"
                            >Atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio3 == '5' }"
                            @click="votos.criterio3 = 5"
                            squared
                            variant="outline-secondary"
                            >Supera</b-button
                        >
                    </div>
                </div>
                <div class="d-none d-xl-block smallTxt ">
                    <b-collapse id="collapse-3" class="mt-2 txtInfo">
                        <span class="small">
                            O projeto atende as necessidades dos clientes (interno/externo) de uma forma direta ou indireta, que os surpreenda positivamente, oferecendo uma solução que supera suas expectativas.
                        </span>
                    </b-collapse>
                </div>
            </div>

            <!--  Foco no cliente -->
            <div class="m-3">
                <div class="d-xl-flex d-block align-items-center">
                    <div class="txt">
                        <p class="titulo m-0">
                          Excelência em<br>Serviços
                          <span v-b-toggle.collapse-4>
                                <b-icon
                                    icon="exclamation-circle"
                                    class="icon"
                                ></b-icon>
                            </span>
                        </p>
                    </div>

                    <div class="d-block d-xl-none smallTxt ">
                        <b-collapse id="collapse-4" class="mt-2 txtInfo">
                            <span class="small">
                                O projeto está alinhado às diretrizes e estratégias da empresa buscando excelência na prestação de serviços e atendimento, que promove o respeito em relação à marca.
                            </span>
                        </b-collapse>
                    </div>

                    <div class="d-lg-flex justify-content-between myBtn">
                        <b-button
                            :class="{ selected: this.votos.criterio4 == '1' }"
                            @click="votos.criterio4 = 1"
                            squared
                            variant="outline-secondary"
                            >Não se aplica</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio4 == '2' }"
                            @click="votos.criterio4 = 2"
                            squared
                            variant="outline-secondary"
                            >Não atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio4 == '3' }"
                            @click="votos.criterio4 = 3"
                            squared
                            variant="outline-secondary"
                            >Atende parcial</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio4 == '4' }"
                            @click="votos.criterio4 = 4"
                            squared
                            variant="outline-secondary"
                            >Atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio4 == '5' }"
                            @click="votos.criterio4 = 5"
                            squared
                            variant="outline-secondary"
                            >Supera</b-button
                        >
                    </div>
                </div>
                <div class="d-none d-xl-block smallTxt ">
                    <b-collapse id="collapse-4" class="mt-2 txtInfo">
                        <span class="small">
                            O projeto está alinhado às diretrizes e estratégias da empresa buscando excelência na prestação de serviços e atendimento, que promove o respeito em relação à marca.
                        </span>
                    </b-collapse>
                </div>
            </div>

            <!--  Pilares de sustentabilidade -->
            <div class="m-3">
                <div class="d-xl-flex d-block align-items-center">
                    <div class="txt">
                        <p class="titulo m-0">
                          Eficiência|Produtividade
                          <span v-b-toggle.collapse-5>
                                <b-icon
                                    icon="exclamation-circle"
                                    class="icon"
                                ></b-icon>
                            </span>
                        </p>
                    </div>

                    <div class="d-block d-xl-none smallTxt ">
                        <b-collapse id="collapse-5" class="mt-2 txtInfo">
                            <span class="small">
                                Projetos que tiveram ganhos expressivos de eficiência a partir de ações relacionadas à redução de custos, incremento/geração de receita e/ou à melhoria contínua, visando, inclusive, à redução da burocracia e da complexidade nas operações e processos internos.
                            </span>
                        </b-collapse>
                    </div>

                    <div class="d-lg-flex justify-content-between myBtn">
                        <b-button
                            :class="{ selected: this.votos.criterio5 == '1' }"
                            @click="votos.criterio5 = 1"
                            squared
                            variant="outline-secondary"
                            >Não se aplica</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio5 == '2' }"
                            @click="votos.criterio5 = 2"
                            squared
                            variant="outline-secondary"
                            >Não atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio5 == '3' }"
                            @click="votos.criterio5 = 3"
                            squared
                            variant="outline-secondary"
                            >Atende parcial</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio5 == '4' }"
                            @click="votos.criterio5 = 4"
                            squared
                            variant="outline-secondary"
                            >Atende</b-button
                        >
                        <b-button
                            :class="{ selected: this.votos.criterio5 == '5' }"
                            @click="votos.criterio5 = 5"
                            squared
                            variant="outline-secondary"
                            >Supera</b-button
                        >
                    </div>
                </div>
                <div class="d-none d-xl-block smallTxt ">
                    <b-collapse id="collapse-5" class="mt-2 txtInfo">
                        <span class="small">
                            Projetos que tiveram ganhos expressivos de eficiência a partir de ações relacionadas à redução de custos, incremento/geração de receita e/ou à melhoria contínua, visando, inclusive, à redução da burocracia e da complexidade nas operações e processos internos.
                        </span>
                    </b-collapse>
                </div>
            </div>

            <div class="row mt-5 mb-3">
                <div
                    class="col-12 d-flex align-items-center justify-content-between"
                >
                    <b-button
                        variant="link"
                        class="d-block m-auto voltar"
                        @click="backTo"
                    >
                        <i class="fas fa-home"></i>
                        <strong class="mx-2"
                            >Voltar para lista de projetos</strong
                        >
                    </b-button>
                    <b-button
                        @click.prevent="detailsSee"
                        pill
                        size="lg"
                        class="d-block m-auto save"
                    >
                        <span>
                            <i class="fas fa-home"></i>
                            <strong class="mx-2">Ver detalhes</strong>
                        </span>
                    </b-button>
                    <b-button
                        pill
                        class="d-block m-auto save"
                        @click="sendVotos"
                        :disabled="!btnDisablad"
                        size="lg"
                    >
                        <span>
                            <i class="fas fa-save "></i>
                            <strong class="mx-2">{{ btnTxt }}</strong>
                        </span>
                    </b-button>
                </div>
                <div class="col btn-footer"></div>
                <div class="col btn-footer"></div>
                <div class="col btn-footer"></div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { api } from '@/services.js';
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'Modal',
    data() {
        return {
            projectId: null,
            votos: {
                criterio1: null,
                criterio2: null,
                criterio3: null,
                criterio4: null,
                criterio5: null,
            },
            btn: {
                txt: 'Preencha todos os campos',
                disabled: false,
            },
            data: {},
        };
    },
    computed: {
        ...mapState(['user', 'keyLogin', 'idProject', 'project']),
        btnDisablad() {
            if (
                this.votos.criterio1 != null &&
                this.votos.criterio2 != null &&
                this.votos.criterio3 != null &&
                this.votos.criterio4 != null &&
                this.votos.criterio5 != null
            ) {
                return true;
            } else {
                return false;
            }
        },
        btnTxt() {
            if (!this.btnDisablad) {
                return 'Preencha todos os campos';
            } else {
                return 'Salvar Votação';
            }
        },
    },
    methods: {
        ...mapMutations(['SET_PROJECT', 'SET_PROJECT_ID']),
        detailsSee() {
            if (this.$route.name !== 'Info')
                this.$router.push(`/info/${this.idProject}`);

            this.closeModal();
        },
        backTo() {
            this.closeModal();
            if (this.$route.name !== 'projetos') this.$router.push(`/projetos`);
        },
        closeModal() {
            this.SET_PROJECT(null);
            this.SET_PROJECT_ID(null);
            this.$refs['modal'].hide();
        },
        resetVotos() {
            this.votos.criterio1 = null;
            this.votos.criterio2 = null;
            this.votos.criterio3 = null;
            this.votos.criterio4 = null;
            this.votos.criterio5 = null;

            this.data = {};

            this.closeModal();
        },
        async sendVotos() {
            let id = null;
            if (this.projectId) id = this.projectId;
            else id = this.idProject;

            this.data = {
                keyAcess: 'oIbfygBUibYcdbNfPsG64tPH80',
                putVoto: true,
                keyLogin: this.keyLogin,
                codigoProjeto: id,
                criterio1: this.votos.criterio1,
                criterio2: this.votos.criterio2,
                criterio3: this.votos.criterio3,
                criterio4: this.votos.criterio4,
                criterio5: this.votos.criterio5,
            };

            const _vn = this;

            await api.post(`/`, _vn.data).then((response) => {
                console.log(response);
                _vn.resetVotos();

                if (this.$route.name === 'projetos') this.$router.go();

                _vn.$router.push('/projetos');
            });
        },
        init() {
            if (this.project) {
                // Object.keys(this.project.votos).forEach((key) => {
                //     console.log(key, this.project.votos[key]);
                // });
                this.votos = { ...this.project.votos };
            }
        },
    },
    created() {
        this.projectId = this.$route.params.projectId;
    },
    watch: {
        idProject() {
            this.init();
        },
    },
};
</script>

<style lang="scss">
$cor1: #fc4f65;
$cor2: #ffffff;
$cor3: #f72717;
$cor4: #6d7787;

.smallTxt {
    font-family: gothicb;
    color: $cor4;
    font-size: 14px;
    line-height: 13px;
}
.txtInfo {
    width: 300px;
    @media (max-width: 1200px) {
        width: 90%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}
.txt {
    width: 200px;
    @media (max-width: 1200px) {
        width: 100%;
        text-align: center;
    }
}
.myBtn {
    width: 100%;
    button {
        width: 150px;
        font-family: gothicb;
        color: $cor4;
        border: 1.5px solid $cor4;
        @media (max-width: 992px) {
            width: 100%;
            margin-bottom: 10px;
        }
        &:hover {
            border: none;
            box-shadow: none;
            background: $cor1;
            color: $cor2;
            border-color: $cor1;
        }
        &:focus {
            box-shadow: none;
            background: $cor1;
            color: $cor2;
            border-color: $cor1;
        }
    }
    .selected {
        background: $cor1;
        color: $cor2;
        border-color: $cor1;
    }
}
.closeModal {
    color: $cor1;
    font-size: 25px;
}
.titulo {
    font-family: gothicb;
    color: $cor1;
    .icon {
        margin-left: 5px;
        color: $cor4;
        &:focus {
            border: none !important;
            box-shadow: none !important;
        }
        &:hover {
            border: none;
            box-shadow: none;
        }
    }
}

.btn-footer {
    margin-top: 20px;

    @media (max-width: 1200px) {
        min-width: 100%;
    }
    a {
        text-decoration: none;
    }
    .voltar {
        text-decoration-color: $cor3;
        &:focus {
            border: none !important;
            box-shadow: none !important;
        }
        &:hover {
            border: none;
            box-shadow: none;
        }
        span {
            color: $cor3;
        }
    }
    .save {
        font-size: 20px;
        background: $cor1;
        border: none;
        &:focus {
            border: none !important;
            box-shadow: none !important;
            background: $cor1;
        }
        &:hover {
            border: none;
            box-shadow: none;
        }
    }
    button {
        width: 80%;
    }
}
</style>
