<template>
    <div>
        <h1 class="mt-5">Login <span>EWARD BRASIL</span></h1>
        <form @submit="handleForm">
            <div class="mb-3">
                <input
                    v-model="form.emailLogin"
                    type="text"
                    class="form-control"
                    placeholder="Usuário"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                />
            </div>
            <div class="mb-3">
                <input
                    v-model="form.senhaLogin"
                    type="password"
                    placeholder="Senha"
                    class="form-control"
                    id="exampleInputPassword1"
                />
            </div>
            <div class="dv-btn">
                <span v-if="error.status" class="message-error">
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 576 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 10px; font-size: 15px;"
                    >
                        <path
                            d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                        ></path>
                    </svg>
                    {{ error.message }}
                </span>
                <button :disabled="btn.disabled" type="submit">
                    {{ btn.message }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { api } from '@/services.js';

export default {
    name: 'Form',
    data() {
        return {
            form: {
                emailLogin: '',
                senhaLogin: '',
                efetuarLogin: true,
                keyAcess: 'oIbfygBUibYcdbNfPsG64tPH80',
            },
            btn: {
                message: 'Entrar',
                disabled: false,
            },
            error: {
                status: false,
                message: 'Usuário e/ou senha inválidos.',
                icon: '',
            },
        };
    },
    methods: {
        async handleForm(e) {
            e.preventDefault();
            this.error.status = false;
            this.btn.message = 'Entrando';
            this.btn.disabled = true;

            await api.post(`/`, this.form).then((response) => {
                if (!response.data.Login) {
                    this.error.status = true;
                    this.btn.message = 'Entrar';
                    this.btn.disabled = false;
                } else {
                    this.$store.commit('UPDATE_USER', response.data.UserData);
                    this.$store.commit('UPDATE_LOGIN', true);
                    this.$store.commit('SET_KEY_LOGIN', response.data.keyLogin);
                    window.localStorage.token = response.data.keyLogin;
                    this.$router.push('/projetos');
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$cor1: #fc4f65;
$cor2: #ffffff;
$cor3: #f72717;
$cor4: #6d7787;
h1 {
    font-family: Edenred, sans-serif !important;
    font-weight: 400 !important;
    color: #555;
    font-size: 21px;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;

    span {
        color: $cor1;
        margin-left: 5px;
    }
}

input {
    padding: 7px 15px;
    border-radius: 30px;
    border: 2px solid rgb(204, 204, 204);
    font-size: 15px;
    font-weight: 600;
    font-family: gothicb;
    color: #000000;
    &::placeholder {
        color: $cor4;
    }
}

.dv-btn {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    font-size: 11px;

    button {
        font-family: gothicb;
        letter-spacing: 0.5px;
        appearance: button;
        // -webkit-writing-mode: horizontal-tb !important;
        text-rendering: auto;
        color: -internal-light-dark(black, white);
        // letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: center;
        align-items: flex-start;
        cursor: default;
        background-color: -internal-light-dark(
            rgb(239, 239, 239),
            rgb(59, 59, 59)
        );
        box-sizing: border-box;
        margin: 0em;
        // font: 400 13.3333px Arial;
        padding: 1px 6px;
        border-width: 2px;
        border-style: outset;
        border-color: -internal-light-dark(
            rgb(118, 118, 118),
            rgb(133, 133, 133)
        );
        border-image: initial;
        color: rgb(255, 255, 255);
        background: $cor1;
        padding: 9px 30px;
        border-radius: 30px;
        border: 0px;
        font-size: 15px;
        transition: all 250ms linear 0s;
        cursor: pointer;
    }
}

.message-error {
    font-family: gothicb;
    margin-right: 15px;
    background: tomato;
    color: rgb(242, 242, 242);
    border-radius: 15px;
    padding: 9px 30px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}
</style>
