<template>
    <header>
        <div v-if="login && user" class="user-info-container">
            <div>
                <div
                    class="img-people"
                    :style="{ background: `url(${user.pathImg})` }"
                ></div>
            </div>
            <div>
                Olá, <br /><span class="user-info-text">{{
                    user.jur_nome
                }}</span>
                <br />
                <button
                    @click="hadleLogOut"
                    style="font-family: Edenred; border: none; background: transparent; color: #711cff; display: flex; height: 20px; align-items: center; cursor: pointer;"
                >
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 5px;"
                    >
                        <path
                            d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                        ></path>
                    </svg>
                    Sair
                </button>
            </div>
        </div>
        <div class="my-container">
            <div class="logo-container-parent">
                <div class="logo-container">
                    <a href="#">
                        <img src="@/assets/img/logo.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/services.js';
export default {
    name: 'Header',
    computed: {
        ...mapState(['login', 'user']),
    },
    methods: {
        hadleLogOut() {
            this.$store.dispatch('logOutUser');
            this.$router.push('/');
        },
        verificarLogin() {
            const token = window.localStorage.token;
            const data = {
                keyAcess: 'oIbfygBUibYcdbNfPsG64tPH80',
                checarLogin: true,
                keyLogin: token,
            };
            if (token) {
                api.post(`/`, data).then((response) => {
                    // console.log(response)
                    // console.log(response.data.Login)
                    if (!response.data.Login) {
                        window.localStorage.removeItem('token');
                        this.$store.commit('UPDATE_LOGIN', false);
                        this.$router.push('/');
                    } else {
                        this.$store.commit(
                            'UPDATE_USER',
                            response.data.UserData
                        );
                        this.$store.commit('UPDATE_LOGIN', true);
                    }
                });
            }
        },
    },
    mounted() {
        this.verificarLogin();
    },
};
</script>

<style lang="scss" scoped>
$cor5: #711cff;
.my-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
}

.img-people {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 100px;
    border: 5px solid #fecad0;
    background-size: cover !important;
    background-position: center !important;
    box-shadow: 0 0 3px 1px #7e6f6f;
}

header {
    background: #fc4f65;
    height: 235px;
    border-bottom: 35px solid #dfe0e3;
    position: relative;

    @media (min-width: 1200px) {
        height: 200px;
        border-width: 75px;
    }

    .logo-container-parent {
        top: 132px;
        bottom: 0;
        background: #fff;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 0px;
        padding-right: 0px;

        width: 100%;
        left: 0px;
        float: left;
        position: relative;

        @media (min-width: 1200px) {
            top: 70px;
            padding: 20px 40px 20px 0;
            border-top-right-radius: 60px;
            border-bottom-right-radius: 60px;

            width: fit-content;
            left: calc(600px - 50vw);
            float: left;
            position: relative;
        }
    }

    .logo-container {
        // padding: 0 15px;
        margin-left: 0px;
        position: relative;

        @media (min-width: 1200px) {
            padding: 0 15px;
            margin-left: calc(50vw - 600px);
            position: relative;
        }

        img {
            display: block;
            margin: auto;
            width: 200px;

            @media (min-width: 1200px) {
                margin: unset;
            }
        }
    }
}

.user-info-container {
    display: -webkit-flex;
    display: flex;
    font-family: Edenred;
    color: #fefefe;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.4;
    position: absolute;
    bottom: 100px;
    right: 30px;

    @media (min-width: 1200px) {
        bottom: 20px;
        right: 15px;
    }
}
</style>
