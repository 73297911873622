import axios from 'axios';

const url = 'https://ws.eward.com.br';

const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.response.use((response) => {
  // if (response.status === 401) {
  //   alert("You are not authorized");
  // }

  if(response.data.status === "Token is Expired"){
    window.localStorage.removeItem("token");
  }

  return response;
}, (error) => {
  if (error.response && error.response.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  }
};
