<template>
    <div>
        <Header />
        <div class="projects-h1-parent">
            <div class="projects-h1">
                <h1 class="m-0">Projetos:</h1>
            </div>
        </div>
        <div class="listagem">
            <Listagem v-if="listProjects.length > 0" />
            <div v-else class="text-center carregando my-2">
                <!-- <b-spinner type="grow"></b-spinner> -->
                <b-spinner class="align-middle mx-3"></b-spinner>
                <strong>Carregando...</strong>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '@/services.js';
import { mapState } from 'vuex';
import Listagem from '@/components/Projetos/Listagem.vue';
export default {
    name: 'Projetos',
    components: {
        Listagem,
    },
    data() {
        return {
            proEspecial: [],
            proGeral: [],
        };
    },
    computed: {
        ...mapState([
            'user',
            'keyLogin',
            'listGeral',
            'listEspecial',
            'listProjects',
        ]),
    },
    methods: {
        verificarLogin() {
            const token = window.localStorage.token;
            // token ? console.log(token) : console.log('Token Espirado')
            this.$store.commit('SET_KEY_LOGIN', token);
        },
        getProjects() {
            api.post('/', {
                keyAcess: 'oIbfygBUibYcdbNfPsG64tPH80',
                getProjetos: true,
                keyLogin: this.keyLogin,
            }).then((response) => {
                // console.log(response)
                response.data.projetos.filter((item) => {
                    item.categoriaUrl == 'especial'
                        ? this.proEspecial.push(item)
                        : this.proGeral.push(item);
                });
                this.$store.commit('SET_LIST_PROJECT', response.data.projetos);
                this.$store.commit('SET_LIST_ESPECIAL', this.proEspecial);
                this.$store.commit('SET_LIST_GERAL', this.proGeral);
            });
        },
    },
    created() {
        this.verificarLogin();
        this.getProjects();
    },
};
</script>

<style lang="scss" scoped>
.carregando {
    color: #00a184;
}
.projects-h1-parent {
    background: #711cff;
    padding-right: 15px;
    padding-left: 100px !important;
    margin: 30px 0;

    @media (min-width: 1200px) {
        width: 100%;
        left: 0px;
        float: left;
        position: relative;
        padding: 10px 60px 10px 0;
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
        width: fit-content;
        left: calc(600px - 50vw);
        float: left;
        position: relative;
    }
}

.projects-h1 {
    margin-left: 0px;
    position: relative;

    @media (min-width: 1200px) {
        margin-left: calc(50vw - 600px);
        position: relative;
    }
}

h1 {
    text-align: left;
    color: #fff;
    font-size: 36px;
    font-family: Edenred, sans-serif !important;
    font-weight: 400 !important;

    @media (min-width: 1200px) {
        text-align: left;
        color: #fff;
        font-size: 36px;
    }
}
.listagem {
    margin-top: 10px;

    @media (min-width: 1200px) {
        margin-top: 130px;
    }
}
</style>
