<template>
    <div>
        <Projects :projects="projects"></Projects>
    </div>
</template>

<script>
import Projects from '@/components/Projetos/listProjects.vue';

export default {
    name: 'tabProjetos',
    props: ['projects'],
    components: {
        Projects,
    },
};
</script>

<style></style>
